
.home-box{
    margin-top:9%;
    .left{
        margin-left: 10.4%;
        width:28.83%;

        img{
            width:81.9%;
        }
        .download{
            margin-top: 12%;
            .download-item{
                position:relative;
                width:46.15%;
                cursor: pointer;
                &:hover{
                    img{
                        display: none;
                        &.check{
                            opacity:1;
                            display: block;
                        }
                    }
                }
                img{
                    width:100%;
                    position:absolute;
                    left:0;
                    top:0;
                    transition:opacity 0.3s ease;
                    display:block;
                    &.check{
                        opacity:0;
                        display: none;
                    }
                }
            }
        }
        &.en{
            width:45.2%;
            img{
                width:100%;
            }
            .download{
                margin-top: 6.8%;
                width:63.8%;
            }
        }
    }
    .right{
        width:16.6%;
        margin-right:15.9%;
        position:relative;
        &:after{
            content:'';
            position:absolute;
            width:224%;
            height: 125.75%;
            left:50%;
            margin-left:-120.5%;
            margin-top:-65.5%;
            background:url('../images/right_bg.png') no-repeat center center;
            background-size: contain;
            right:0;
            top:0;
            z-index:1;
        }
        .bannerWrapper{
            padding-bottom: 200%;
            // position:absolute;
            // width:100%;
            // height: 100%;
            // left:50%;
            // transform:translateX(-50%);
            // bottom:0;
            .carousel__container{
                height:100%;
                position:absolute;
                width:100%;
                .carousel__item{
                    position:absolute;
                    left:50%;
                    bottom:0;
                    width:153%;
                    margin-left:-76.5%;
                    transform-origin: bottom center;
                    z-index:1;
                    transition: all 0.38s ease-out;

                    img{
                        width:100%;
                        height: 100%;
                        border-radius:16px;
                    }
                    &.item0{
                        z-index:10;
                        transform:translate3d(-32%,0,0) scale(0.65);
                        bottom:11.5%;

                    }
                    &.item1{
                        z-index:12;
                        transform:translate3d(0,0,0) scale(1);
                        &:after{
                            opacity:0;
                        }
                    }
                    &.item2{
                        z-index:10;
                        bottom:11.5%;
                        transform:translate3d(32%,0,0) scale(0.65);
                    }

                }
            }

        }
    }

}

.footer{
    z-index:20;
}

.line{
    position:absolute;
    bottom:7%;
    left:0;
    text-align: center;
    width:100%;

    span{
        width: 0.3rem;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 0.06rem;
        background: #E8E8E8;
        position:relative;
        z-index:100;
        border-radius: 3px;
        margin:0 0.15rem;
        &.line-cur{
            background:var(--primary-color);
        }
    }
}


.wrapper{
    height:0.6rem;
    position:relative;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 0.24rem;
    color:#212121;
    font-weight: 500;
    .logo img{
        width:0.6rem;
        position:absolute;
        left:0;
        top:0;
    }
}
.f-line{
    width:100%;
    height:1px;
    background:#E6E9F0;
    margin-top:0.4rem;
    margin-bottom: 5px;
}

.page-home{
    background:url('../images/bg.png') no-repeat center center;
    background-size: cover;
}
