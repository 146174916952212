.lang{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
    position:relative;
    z-index:100;
    .lang-item:first-child{
        position:relative;
       &:after{
           content:"";
           width:1px;
           height: 0.2rem;
           background-color: #ccc;
           display: block;
           position:absolute;
           right:0;
           top:50%;
           transform:translateY(-50%);
       }
    }
}


